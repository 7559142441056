<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-center my-12 text-h4">Loan Management System</div>
        <v-card class="mx-auto py-10 px-5" max-width="374">
          <div class="text-center mb-5 text-h5">Login</div>
          <v-alert type="error" v-if="loginError">{{loginError}}</v-alert>
          <v-text-field
            v-model="Email"
            label="Email/userName"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required
          ></v-text-field>
          <div class="text-center">
            <v-btn color="primary" class="px-10 my-5" @click="login()" :disabled="loading">Login</v-btn><br>
             <v-progress-circular
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-circular>
          </div>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import {mapGetters} from "vuex"
import {user_login} from '@api/authorizations/login'
export default {
  data(){
    return {
      Email:"",
      password:"",
      loginError:'',
      loading:false
    }
  },
  computed: {
    // ...mapGetters('Login',['loginError','loading'])
  },
  methods:{
    login(){
      let data={
        email:this.Email,
        password:this.password,
      }
      this.loading=true;
      user_login(data).then(resp=>{
        this.loading=false;
        if(resp.status==='Success'){
          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("user_role",resp.data.role);
          this.$store.dispatch('Login/loginDetails',resp.data.userData)
          window.location.href="/";
          if(resp.data.role===2){
            this.$store.dispatch('Login/setPermissions',resp.data.permission)
            localStorage.setItem("user_permission", resp.data.permission)
          }
        }else{
          this.loginError= resp.errors;
          // commit('loginError',resp.data.errors);
        }
      })
      // this.$store.dispatch('Login/login',{email:this.Email,password:this.password});
    }
  }
};
</script>

<style></style>
